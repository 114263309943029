import request from './request'
import { QueryPageDto, QueryDateSlotDto } from './dto'
interface GetEnterpriseNum{
    projectCount:number,
    peopleCount:number,
    serveCompanyCount:number
}
// 获取某公司下长期工项目数,打卡人数
export function getSignTopNum (data:{queryType:number | '', beginCreateTime:string, endCreateTime:string}): Promise<GetEnterpriseNum> {
    return request.get('/rest/pay/blueSign/project-people-count', {
        params: data
    })
}
interface GetSignDetailInfoDto extends QueryPageDto, QueryDateSlotDto {
    projectId:string,
    serveCompanyId:string,
    userId:string
    queryType?:string | number
}
// 获取详细信息
export function getSignDetailInfo (data:GetSignDetailInfoDto):Promise<{ excelHeadInfo:string[], hasNextPage:boolean, list: string[], totalCount: number }> {
    return request.get('/rest/pay/blueSign/sign-in-record-list', {
        params: data
    })
}
interface GetSignImgListDto extends QueryDateSlotDto{
    userId:number,
    projectId:number
}
// 获取图片列表
export function getSignImgList (data:GetSignImgListDto):Promise<string[]> {
    return request.get('/rest/pay/blueSign/sign-voucher', {
        params: data
    })
}
