
import { defineComponent } from 'vue'
import { exportSign, exportSignVertical } from '@/api/export'
import { getSignDetailInfo, getSignImgList } from '@/api/sign'
// import { number } from 'echarts'
// interface objEntDto {
//     [key: string]: string
// }
export default defineComponent({
    name: 'SignDetail',
    data () {
        return {
            infinite: false,
            tableData: [] as string[],
            tabHeadInfo: [] as string[],
            pageNum: 1,
            pageSize: 10,
            total: 0,
            searchText: '',
            searchValue: '',
            selects: [] as string[],
            id: '',
            detailType: '',
            timeEnd: '',
            timeStart: '',
            enterpriseName: '',
            showImageViewer: false,
            imageList: [] as string[],
            previewIndex: 0 as number,
            projectName: '',
            queryType: 1
        }
    },
    created () {
        const { id, type, start, end, title, name } = this.$route.params as {
            [key: string]: string
        }
        this.id = id
        this.detailType = type
        this.timeEnd = end
        this.timeStart = start
        this.enterpriseName = title
        this.projectName = name
        this.getSignDetailInfo()
    },
    methods: {
        onQueryType (event:number) {
            console.log(event, 'event')
            this.queryType = event
            this.getSignDetailInfo()
        },
        async getSignDetailInfo () {
            const result = await getSignDetailInfo({
                beginCreateTime: this.timeStart,
                endCreateTime: this.timeEnd,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                projectId: this.detailType === '0' ? this.id : '',
                serveCompanyId: this.detailType === '1' ? this.id : '',
                userId: this.detailType === '2' ? this.id : '',
                searchText: this.searchText,
                queryType: this.queryType
            })
            this.total = result.totalCount
            this.tabHeadInfo = result.excelHeadInfo
            // console.log(this.tabHeadInfo, 'tabHeadInfo')
            // const arr = [] as Array<objEntDto>
            // const brr: objEntDto = {}
            // console.log(result.list, 'result.list')
            // result.list.forEach(item => {
            //     console.log(item, 'item')
            //     Object.keys(item).forEach(key => {
            //         brr[key] = ((item) as unknown as { [key: string]: string })[
            //             key
            //         ]
            //     })
            //     arr.push({
            //         ...brr
            //     })
            // })
            this.tableData = result.list
            console.log(this.tabHeadInfo, 'tabHeadInfo')
            console.log(this.tableData, 'tableData')
        },
        handleSizeChange () {
            this.pageNum = 1
            this.getSignDetailInfo()
        },
        handleCurrentChange () {
            this.getSignDetailInfo()
        },
        async onExportVertical () {
            const result = await exportSignVertical({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                beginCreateTime: this.timeStart,
                endCreateTime: this.timeEnd,
                projectId: this.detailType === '0' ? this.id : '',
                serveCompanyId: this.detailType === '1' ? this.id : '',
                userId: this.detailType === '2' ? this.id : '',
                searchText: this.searchText,
                queryType: this.queryType
            })
            window.location.href =
                process.env.VUE_APP_BASE_URL +
                    '/common/download?delete=true&fileName=' +
                    JSON.parse(JSON.stringify(result)).data

            this.$message.success('导出成功！')
        },
        async onExport () {
            const result = await exportSign({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                beginCreateTime: this.timeStart,
                endCreateTime: this.timeEnd,
                projectId: this.detailType === '0' ? this.id : '',
                serveCompanyId: this.detailType === '1' ? this.id : '',
                userId: this.detailType === '2' ? this.id : '',
                searchText: this.searchText,
                queryType: this.queryType
            })
            window.location.href =
                process.env.VUE_APP_BASE_URL +
                    '/common/download?delete=true&fileName=' +
                    JSON.parse(JSON.stringify(result)).data

            this.$message.success('导出成功！')
        },
        // 点击查看图片
        async preview (userId: string, projectId: string, date: string) {
            console.log(userId, projectId, date)
            const result = await getSignImgList({
                beginCreateTime: this.timeStart,
                endCreateTime: this.timeEnd,
                userId: Number(userId),
                projectId: Number(projectId)
            })
            // this.imageList.push()
            this.imageList = []
            let str = 0
            let previewIndex = 0
            result.forEach((item, index) => {
                // if (JSON.parse(JSON.stringify(item)).date === date) {
                //     previewIndex = index
                // }
                if (JSON.parse(JSON.stringify(item)).signInVoucher) {
                    this.imageList.push(
                        JSON.parse(JSON.stringify(item)).signInVoucher
                    )
                } else {
                    str += 1
                }
                if (JSON.parse(JSON.stringify(item)).signOutVoucher) {
                    this.imageList.push(
                        JSON.parse(JSON.stringify(item)).signOutVoucher
                    )
                } else {
                    str += 1
                }
            })

            const index = result.findIndex(item => JSON.parse(JSON.stringify(item)).date === date)
            const cur = (result[index] as any).signInVoucher || (result[index] as any).signOutVoucher
            const curIndex = this.imageList.findIndex(i => i === cur)
            if (curIndex!==-1) {
                this.previewIndex = curIndex
            }
            if (this.previewIndex === 0) {
                // this.previewIndex = 0
                this.showImageViewer = true
                return
            }
            console.log(str, 'strstr')
            console.log(previewIndex, 'previewIndex')
            // this.previewIndex =
            // previewIndex * 2 - str <= 0 ? previewIndex : (previewIndex) * 2 - str
            // console.log(this.previewIndex, 'this.previewIndex')
            //
            // console.log(this.previewIndex, 'previewIndex')
            this.showImageViewer = true
        },
        closeImgViewer () {
            this.showImageViewer = false
        },
        onReset () {
            this.searchText = ''
            this.pageNum = 1
            this.getSignDetailInfo()
        }
    }
})
